.kategorienAnzeigen h2 {
	text-align: center;
	font-size: 25px;
	font-weight: 800;
}

.kategorienAnzeigen header h2 {
	color: #fff;
	margin-top: 50px;
	text-align: center;
}

.kategorienAnzeigen header h3 {
	text-align: center;
	color: #fff;
	font-size: 14px;
}

.unterkategorienNav {
	background-color: #fff;
	padding-bottom: 50px;
}

.kategorienAnzeigen .unterkategorienNav h2 {
	font-size: 25px;
	padding: 10px;
}

.unterkategorien {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.unterkategorien button {
	min-width: 150px;
	height: 90px;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: lightgray;
	margin: 10px;
	text-align: center;
	text-transform: uppercase;
	border: none;
	border-radius: 5px;
	color: white;
	font-size: 14px;
	font-weight: 800;
	cursor: pointer;
	text-decoration: none;
	text-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
}

.unterkategorien button:hover {
	background-size: 200% 200%;
	background-image: url("../../img/Verlauf.png");
	background-attachment: fixed;
	animation: diagonal alternate 30s infinite;
}

.unterkategorien button.active {
	background-size: 200% 200%;
	background-image: url("../../img/Verlauf.png");
	background-attachment: fixed;
	animation: diagonal alternate 30s infinite;
}

.kategorienAnzeigen main section.ergebnisseWrapper {
	background-color: #f6f6f5;
	padding: 70px 70px 100px 70px;
	padding: 50px 20px;
}

.keineErgebnisse {
	text-align: center;
	line-height: 1.7em;
}

.keineErgebnisse img {
	width: 65px;
	margin-bottom: 15px;
}

.keineErgebnisse p {
	font-weight: 600;
}

.ergebnisseWrapper .headline {
	font-size: 35px;
	text-align: center;
	margin-bottom: 30px;
}

.ergebnisse {
	display: grid;
	grid-template-columns: 1fr;
	gap: 30px;
}

.ergebnisse * {
	box-sizing: border-box;
}

.ergebnisse > div {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	grid-template-areas:
		"offerBanner"
		"top"
		"merkliste" "notdienst" "anfrage";
	font-size: 14px;
}

.ergebnisse > div .top {
	display: flex;
	flex-direction: column;
	grid-area: top;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	margin-bottom: 5px;
}

.ergebnisse > div .top > img {
	width: 100%;
	height: 100%;
	max-height: 200px;
	object-fit: contain;
	background-color: white;
}

.ergebnisse > div .top > div {
	background-color: #fff;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 10px;
	width: 100%;
}

.ergebnisse > div .top > div h4 {
	font-size: 16px;
}

.ergebnisse > div .top > div ul {
	line-height: 1.6em;
	list-style: none;
	padding-left: 0;
}

.ergebnisse > div .top > div ul li {
	display: flex;
	align-items: center;
	gap: 10px;
}

.ergebnisse > div .top > div button {
	border: none;
	background: #fff;
	padding: 6px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	cursor: pointer;
}

.ergebnisse > div > button {
	border: none;
	background: #fff;
	padding: 10px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	font-size: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: left;
	gap: 10px;
	margin-bottom: 5px;
	cursor: pointer;
}

.ergebnisse > div button.btnMerkliste {
	grid-area: merkliste;
}

.ergebnisse > div button.btnNotdienst {
	grid-area: notdienst;
}

.ergebnisse > div button.btnAnfrage {
	grid-area: anfrage;
}

.ergebnisse > div button.btnAnfrage span {
	font-size: 11px;
	font-weight: 700;
}

@media (min-width: 500px) {
	.kategorienAnzeigen header {
		padding: 20px 45px;
	}
}


@media (min-width: 700px) {
	.kategorienAnzeigen main section.ergebnisseWrapper {
		padding: 70px 50px;
	}

	.ergebnisse > div {
		display: grid;
		grid-template-columns: 21% 21% 58%;
		grid-template-rows: auto;
		grid-template-areas:
			"offerBanner offerBanner offerBanner"
			"top top top"
			"notdienst merkliste anfrage"
			"infoBanner infoBanner infoBanner";
		font-size: 14px;
	}

	.ergebnisse > div .top {
		flex-direction: row;
	}

	.ergebnisse > div .top > img {
		width: 42%;
		border-radius: 5px 0 0 0;
	}

	.ergebnisse > div .top > div {
		width: 58%;
		border-radius: 0 5px 0 0;
	}

	.ergebnisse > div button.btnMerkliste {
		margin-right: 5px;
		margin-bottom: 0;
		border-radius: 0 0 0 5px;
		position: relative;
	}

	.ergebnisse > div button.btnNotdienst {
		margin-right: 5px;
		margin-bottom: 0;
	}

	.ergebnisse > div button.btnAnfrage {
		border-radius: 0 0 5px 0;
		margin-bottom: 0;
	}
}

@media (min-width: 981px) {
	.kategorienAnzeigen h2 {
		font-size: 35px;
	}
}

@media (min-width: 1300px) {
	.ergebnisse {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 60px;
	}
}
