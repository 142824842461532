.backgroundAnimation {
	background-size: 200% 200%;
	background-image: url("../../img/Verlauf.png");
	background-attachment: fixed;
	animation: diagonal alternate 30s infinite;
	position: relative;
}

@keyframes diagonal {
	0% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 50%;
	}
}
