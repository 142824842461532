.MemoryList {
    max-width: 1400px;
    margin: auto;
    padding-bottom: 50px;
}

.ml_buttonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ml_button {
    padding: 15px 25px 15px 25px;
    background-color: white;
    text-align: center;
    font-weight: 700;
    border-radius: 5px;
    cursor: pointer;
    word-break: break-word;
    margin: 15px 10px 0 10px;
    text-transform: uppercase;
}

.ml_button:hover {
    color: white;
    background-size: 200% 200%;
	background-image: url("../../img/Verlauf.png");
	background-attachment: fixed;
	animation: diagonal alternate 30s infinite;
	position: relative;
}

.ml_button.active {
    color: white;
    background-size: 200% 200%;
	background-image: url("../../img/Verlauf.png");
	background-attachment: fixed;
	animation: diagonal alternate 30s infinite;
	position: relative;
}

.ml_date {
    padding-top: 5px;
}


@media only screen and (max-width: 500px) {
    .ml_buttonContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
