nav.scrollNav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 20px 25px;
	z-index: 100;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 70px auto;
	grid-template-areas:
		"logo navToggle"
		"content content";
	align-items: center;
	color: #fff;
	font-weight: 600;
	transition: padding 0.3s ease;
}

nav.scrollNav > * {
	box-sizing: border-box;
}

nav.scrollNav img {
	grid-area: logo;
	width: 150px;
	transition: width 0.3s ease;
	cursor: pointer;
}

nav.scrollNav > label {
	grid-area: navToggle;
	padding: 20px 3px;
	justify-self: end;
	cursor: pointer;
}

nav.scrollNav > input {
	display: none;
}

nav.scrollNav .navIcon,
nav.scrollNav .navIcon::before,
nav.scrollNav .navIcon::after {
	width: 40px;
	height: 2px;
	background-color: #fff;
	transition: 0.2s ease;
}

nav.scrollNav .navIcon {
	position: relative;
}

nav.scrollNav .navIcon::before,
nav.scrollNav .navIcon::after {
	content: "";
	display: block;
	position: absolute;
}

nav.scrollNav .navIcon::before {
	top: -10px;
}

nav.scrollNav .navIcon::after {
	bottom: -10px;
}

nav.scrollNav ul {
	grid-area: nav;
	list-style: none;
	padding: 0;
	margin-top: 10px;
	background: #fff;
	border-radius: 5px;
	color: #000;
	font-size: 14px;
	line-height: 1.5em;
}

nav.scrollNav ul li {
	padding: 10px 20px;
	cursor: pointer;
}

nav.scrollNav ul li:not(:last-child) {
	border-bottom: solid 1px lightgrey;
}

nav.scrollNav .content {
	grid-area: content;
	visibility: hidden;
	height: 0;
}

nav.scrollNav > input:checked ~ .content {
	visibility: visible;
	height: auto;
	padding-bottom: 10px;
}

nav.scrollNav > input:checked ~ label .navIcon {
	background-color: transparent;
}

nav.scrollNav > input:checked ~ label .navIcon::before {
	transform: rotate(45deg);
	top: 0;
}

nav.scrollNav > input:checked ~ label .navIcon::after {
	transform: rotate(-45deg);
	bottom: 0;
}

nav.scrollNav.scrolled {
	padding: 10px 25px;
}

nav.scrollNav.scrolled img {
	width: 120px;
}

nav.scrollNav.onlyVisibleOnScroll {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	transform: translateY(-100%);
	transition: transform 0.3s ease;
}

nav.scrollNav.onlyVisibleOnScroll .wrapperBtnNotfall{
	overflow: hidden;
}

nav.scrollNav.onlyVisibleOnScroll.scrolled .wrapperBtnNotfall{
	overflow: visible;
}

nav.scrollNav.onlyVisibleOnScroll.scrolled {
	transform: translateY(0);
}

@media (min-width: 500px) {
	nav.scrollNav {
		padding: 25px 45px;
	}

	nav.scrollNav.scrolled {
		padding: 15px 45px;
	}
}

@media (min-width: 1016px) {
	nav.scrollNav {
		grid-template-columns: 1fr auto 1fr;
		grid-template-rows: auto;
		grid-template-areas: "logo horizontalForm navToggle"
							 "content content content";
		grid-gap: 0 30px;
		justify-content: stretch;
	}
}
