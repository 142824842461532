.bigButtons {
	max-width: 1200px;
	margin: auto;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	justify-items: center;
	grid-gap: 30px;
}

@media (min-width: 768px) {
	.bigButtons {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (min-width: 1300px) {
	.bigButtons {
		grid-gap: 60px;
	}
}
