.Gallery_PreviewContainer img, .Gallery_PreviewContainer .videoPlayer {
    display: block;
    width: calc(100% - 2px);
    height: 400px;   
    object-fit: contain; 
    border-radius: 2px;
    box-shadow: 1px 1px 5px hsla(0, 0%, 0%, 0.102);
    background-color: white;
}

.Gallery_PreviewContainer {
    position: relative;
}

.Gallery_ImageContainer {
    display: flex;
    flex-wrap: wrap;
}

.Gallery_SmallImage {
    cursor: pointer;
}

.Gallery_SmallImage img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    padding: 2px 2px 0 0;
    display: block;
    border-radius: 2px;
    box-shadow: 1px 1px 5px #0000001a;
}

.ArrowContainer {
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
}

.ArrowContainer svg {
    margin: 0 10px 0 10px;
}


