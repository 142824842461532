.customerStart h2 {
	text-align: center;
	font-size: 25px;
	font-weight: 800;
	text-transform: uppercase;
}

.customerStart header h2 {
	color: #fff;
	margin-top: 30px;
	margin-bottom: 30px;
}

.customerStart main section {
	padding: 50px 20px;
}

.customerStart main section.wrapperAngebotStoebern {
	background: #fff;
}

.customerStart main h2 {
	color: #535353;
	margin-bottom: 30px;
}

.customerStart main .angebotStoebern button {
	width: 100%;
	max-width: 400px;
	border: none;
	border-radius: 5px;
	color: #fff;
	font-size: 25px;
	font-weight: 700;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 60px 0;
	cursor: pointer;
	text-transform: uppercase;
}

.customerStart main .angebotStoebern button img {
	width: 70px;
	margin-bottom: 15px;
}

.customerStart .wrapperAngebotStoebern + .trenner {
	background: #fff;
}

.customerStart .wrapperSoFunktioniertIventi .bulletpoints {
	list-style-type: none;
	padding: 0;
	display: flex;
	flex-direction: column;
}

.customerStart .wrapperSoFunktioniertIventi .bulletpoints > div:first-child {
	margin-bottom: 90px;
}

.customerStart
	.wrapperSoFunktioniertIventi
	.bulletpoints
	.point:not(:last-child) {
	margin-bottom: 90px;
}

.customerStart .wrapperSoFunktioniertIventi .bulletpoints .point {
	flex-basis: 100%;
	flex-shrink: 0;
}

.customerStart .wrapperSoFunktioniertIventi .point .content .panel::after {
	background-image: linear-gradient(
		rgba(255, 255, 255, 0.001) 53px,
		#f6f6f5
	) !important;
}

.customerStart .wrapperStaedte {
	background-color: #fff;
}

.customerStart .wrapperStaedte p {
	text-align: center;
	margin-bottom: 50px;
	line-height: 1.6;
}

@media (min-width: 500px) {
	.customerStart main section {
		padding: 70px 50px;
	}
}

@media (min-width: 981px) {
	.customerStart h2 {
		font-size: 35px;
	}

	.customerStart .wrapperSoFunktioniertIventi .bulletpoints {
		max-width: 1200px;
		margin: auto;
		flex-direction: row;
		justify-content: space-between;
	}

	.customerStart .wrapperSoFunktioniertIventi .bulletpoints > div {
		flex-basis: 48%;
	}
}
