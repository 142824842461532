* {
  margin: 0;
  font-family: 'Spartan', sans-serif;
}

body {
  background-color: #F6F6F5;
  color: #525252;
}

.headlineNormal {
  padding-top: 20px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 25px;
  color: #525252;
}

.textField {
  margin-top: 20px;
  width: 100%;
  padding: 20px;
  outline: none;
  border: unset;
  box-shadow: 1px 1px 5px #0000001a;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
}

.textFieldQuick {
  outline: none;
  border: unset;
  box-sizing: border-box;
  font-size: 16px;
  background: unset;
}

.submitAdButton {
  padding: 20px 40px 20px 40px;
  background-color: #f9b300;
  color: white;
  font-weight: 800;
  font-size: 18px;
  outline: none;
  border: unset;
  border-radius: 5px;
  cursor: pointer;
  min-width: 200px;
}

.loadingButton {
  padding: 12px 40px 12px 40px;
  background-color: #f9b300;
  color: white;
  font-weight: 800;
  font-size: 18px;
  outline: none;
  border: unset;
  border-radius: 5px;
  cursor: pointer;
  min-width: 200px;
}

.error {
  text-align: center;
  color: red;
  margin-top: 10px;
}

.textFieldUnderline {
  border: unset;
  outline: none;
  border-bottom: 1px solid #0000001a;
  padding: 10px 0 10px 0;
}


/* LINE BREAKS */

.lineBreakTopWhite,
.lineBreakBottomGrey {
  background-size: 200% 200%;
  background-image: url('./img/Verlauf.png');
  background-attachment: fixed;
  animation: diagonal alternate 30s infinite;
  margin-bottom: -1px;
}

.DayPicker-Day--today {
  color: black !important;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f9b30075 !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #F9B300 !important;
}

.footer a {
  text-decoration: none;
  color: #525252;
}

.ergebnisse .btnMerkliste {
  border-radius: 0px !important;
}



@media only screen and (max-width: 700px) {
  .ergebnisse .btnNotdienst {
    border-radius: 0px!important;
  }
}