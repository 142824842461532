.SearchResults header {
    padding-bottom: 120px;
}

.SearchResults .keineErgebnisse {
    padding: 80px 50px 80px 50px;
}

.buttonSmallAd {
    background: #F9B300!important;
    border-radius: 5px;
    color: white;
}