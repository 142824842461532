.zurueckButtonWrapper {
	max-width: 1300px;
	box-sizing: border-box;
	margin: auto;
	padding: 0 20px;
}

.zurueckButtonWrapper button {
	background-color: #fff;
	border: none;
	border-radius: 5px;
	padding: 10px 20px;
	box-shadow: 1px 1px 5px #0000001a;
	cursor: pointer;
}
