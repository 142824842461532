.showAdWrapper h2 {
	text-align: center;
	font-size: 25px;
	font-weight: 800;
}

.showAdWrapper header {
	padding-bottom: 0;
}

.showAdWrapper header h2 {
	color: #fff;
	margin-top: 50px;
}

.showAdWrapper header h3 {
	text-align: center;
	color: #fff;
	font-size: 14px;
}

.showAdWrapper .showAd,
.showAdWrapper .showAd * {
	box-sizing: border-box;
}

.showAdWrapper .showAd {
	max-width: 1300px;
	margin: auto;
	display: flex;
	flex-direction: column;
	margin-bottom: 50px;
	margin-top: 10px;
	padding: 20px;
}

.showAdWrapper .SA_description {
	background-color: white;
	padding: 20px;
	border-radius: 10px;
}

.showAdWrapper .SA_description {
	margin-top: 30px;
	box-shadow: 1px 1px 5px #0000001a;
}

.showAdWrapper .SA_flexTwo .topButtons {
	display: flex;
	gap: 15px;
	margin: 30px 0;
}

.showAdWrapper .SA_flexTwo .topButtons button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	background-color: #fff;
	border: none;
	padding: 13px 10px;
	border-radius: 10px;
	box-shadow: 1px 1px 5px #0000001a;
	font-size: 15px;
	font-weight: 600;
	cursor: pointer;
}

.showAdWrapper .SA_flexTwo .topButtons button span {
	display: inline-block;
	text-align: left;
}

.showAdWrapper .SA_flexTwo .topButtons button.btnMerkliste {
	color: #fff;
	flex-basis: 65%;
}

.showAdWrapper .SA_flexTwo .topButtons button.btnNotdienst {
	flex-basis: 35%;
	flex-basis: 100%;
}

.showAdWrapper .SA_flexTwo .topButtons button.btnNotdienst span {
	transform: translateY(2px);
}

.showAdWrapper .SA_flexTwo .topButtons button.disabled {
	cursor: not-allowed;
}

.showAdWrapper .SA_flexTwo .topButtons button.disabled img,
.showAdWrapper .SA_flexTwo .topButtons button.disabled span {
	opacity: 0.1;
}

.showAdWrapper .showAd_services {
	background-color: white;
	padding: 30px;
	font-size: 14px;
	box-shadow: 1px 1px 5px #0000001a;
	border-radius: 10px;
	margin-top: 30px;
}

.showAdWrapper .showAd_services p {
	padding-bottom: 20px;
}

.showAdWrapper .SA_flexTwo .kurzinfo {
	margin-top: 30px;
}

.showAdWrapper .SA_flexTwo .infoPoint {
	display: flex;
	align-items: center;
	margin-bottom: 6px;
}

.showAdWrapper .SA_flexTwo ul {
	display: flex;
	flex-wrap: wrap;
	padding-left: 17px;
	gap: 6px 30px;
}

.showAdWrapper .SA_flexTwo ul li {
	flex-basis: calc(50% - 15px);
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

.showAdWrapper .SA_flexTwo .btnAnfrage {
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	background-color: #fff;
	border: none;
	padding: 15px 15px;
	border-radius: 10px;
	box-shadow: 1px 1px 5px #0000001a;
	font-size: 14px;
	text-align: left;
	font-weight: 400;
	cursor: pointer;
	hyphens: auto;
}

.showAdWrapper .SA_flexTwo .btnAnfrage span {
	font-weight: 700;
	margin-right: 5px;
}

@media (min-width: 768px) {
	.showAdWrapper .showAd {
		flex-direction: row;
		gap: 30px;
	}

	.showAdWrapper .SA_flexOne {
		width: 53%;
	}

	.showAdWrapper .SA_flexTwo {
		width: 47%;
	}

	.showAdWrapper .SA_flexTwo .topButtons {
		display: flex;
		gap: 15px;
		margin: 0 0 30px 0;
	}

	.showAdWrapper .SA_flexTwo .kurzinfo {
		margin-top: 0;
	}
}

.offerBannerCustomer {
	padding: 15px!important;
	border-radius: 10px!important;
	margin-bottom: 25px!important;
}

@media (min-width: 981px) {
	.showAdWrapper h2 {
		font-size: 35px;
	}
}
