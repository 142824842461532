.cat_container {
    display: flex;
    background-color: white;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
}

.cat_content {
    max-width: 80%;
    margin: auto;
}