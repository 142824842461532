.toggleSwitchUser {
    background-color: white;
    padding: 20px 30px 20px 30px;
    margin: 10px;
    border-radius: 5px;
    font-weight: 800;
    text-transform: uppercase;
    cursor: pointer;
}

.toggleSwitchUser.active {
    color: white;
    background-size: 200% 200%;
	background-image: url("../../img/Verlauf.png");
	background-attachment: fixed;
	animation: diagonal alternate 30s infinite;
	position: relative;
}

.toggleSwitchContainer {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}