.ergebnisseWrapper {
    max-width: 1400px;
    margin: auto;
    width: 90%;
    padding: 40px 0 100px 0;
}

.headline {
    text-transform: uppercase;
    color: black;
}

.headline h2 {
    font-weight: 800;
}

.choosePost:hover {
    box-shadow: 0 0 0 5px #FAB300;
    border-radius: 2px;
}

.choosePost {
    cursor: pointer;
}

.ergebnisseCenter{
    display: grid;
    grid-template-columns: 1fr!important;
    grid-gap: 60px;
    gap: 60px;
    margin: 40px 0 40px 0;
}

.noHover:hover {
    box-shadow: unset;
}

.offerBanner {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    grid-area: offerBanner;
    padding: 5px 15px 5px 15px;
    background: linear-gradient(45deg, #da8200, #eda514 , #ffde54,  #da8200, #eda514 , #ffde54,  #da8200, #eda514 , #ffde54);
    border-radius: 5px 5px 0 0;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    align-items: center;
    color: white;
    font-weight: 800;
    text-transform: uppercase;
}

.offerBanner img {
    width: 40px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.textFieldInformation {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin: auto;
}

.textFieldInformation input {
    width: 80px;
}

.textFieldInformation p {
    margin-top: 20px;
    margin-left: 10px;
    font-weight: 800;
}