.point {
    display: flex;
  }
  
  .point .icon {
    width: 90px;
    height: 90px;
    background-size: 200% 200%;
    background-image: url('../../img/Verlauf.png');
    background-attachment: fixed;
    animation: diagonal alternate 30s infinite;
    margin-right: 20px;
    border-radius: 50%;
  }
  
  .point img {
    width: inherit;
    height: inherit;
    transition: transform 0.3s ease;
  }
  
  .point h3 {
    font-family: "Spartan", Helvetica, Arial, Lucida, sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #525252 !important;
    line-height: 1.5em;
  }
  
  .point p {
    font-family: "Spartan", Helvetica, Arial, Lucida, sans-serif;
    font-size: 16px;
    color: #525252 !important;
    line-height: 28px;
  }
  
  .point .content {
    position: relative;
  }
  
  .point .content .panel {
    max-height: 80px;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .point .content .panel::after {
    content: "";
    display: inline-block;
    position: absolute;
    pointer-events: none;
    height: 100px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.3s ease;
    background-image: -webkit-linear-gradient(
      rgba(255, 255, 255, 0.001) 53px,
      white
    );
    background-image: -moz-linear-gradient(
      rgba(255, 255, 255, 0.001) 53px,
      white
    );
    background-image: -o-linear-gradient(rgba(255, 255, 255, 0.001) 53px, white);
    background-image: linear-gradient(rgba(255, 255, 255, 0.001) 53px, white);
  }
  
  .point .content button {
    background: transparent;
    outline: none;
    border: none;
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translate(-50%, 0);
    cursor: pointer;
    padding: 0px;
  }
  
  .point .content .panel.expanded {
    max-height: 2000px;
  }
  
  .point .content .panel.expanded::after {
    bottom: -30px;
  }
  
  .point .content .panel.expanded + button img {
    transform: rotate(180deg);
  }
  