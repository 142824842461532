.popupWrapper {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.3);
	z-index: 200;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-y: auto;
}

.popupWrapper .popup {
	position: relative;
	background-color: #fff;
	border-radius: 15px;
	padding: 25px;
	width: 1000px;
	max-width: 90%;
	box-sizing: border-box;
}

.popupWrapper .popup .closeButton {
	position: absolute;
	top: 12px;
	right: 20px;
	background-color: transparent;
	border: none;
	cursor: pointer;
	color: #000;
	font-size: 26px;
	font-weight: 400;
	transform: rotate(45deg);
}

@media (min-width: 470px) {
	.popupWrapper {
		padding: 20px;
	}

	.popupWrapper .popup {
		padding: 50px;
	}
}
