footer {
	padding: 50px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
	background-color: #535353;
	gap: 30px;
	color: #fff;
	font-size: 14px;
	line-height: 1.8em;
}

footer h5 {
	font-size: 14px;
	font-weight: 700;
}

footer a {
	display: block;
	color: #fff;
	text-decoration: none;
}

footer .footerLogo {
	width: 150px;
	margin-bottom: 20px;
}

footer .socialMedia {
	display: inline-grid;
	grid-template-columns: repeat(6, 1fr);
	align-content: start;
	grid-gap: 15px;
	justify-items: center;
	align-items: center;
}

footer .socialMedia a {
	line-height: 0;
}

footer .socialMedia img {
	height: 20px;
	opacity: 0.8;
	transition: opacity 0.3s ease;
}

footer .socialMedia img:hover {
	opacity: 1;
}

@media (min-width: 768px) {
	footer {
		padding: 90px 30px 40px 30px;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		gap: 35px;
		text-align: left;
	}

	footer > div {
		flex-shrink: 1;
	}

	footer > div:first-child {
		margin-top: -40px;
	}
}

@media (min-width: 915px) {
	footer .socialMedia {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (min-width: 1115px) {
	footer {
		padding: 90px 80px 40px 80px;
	}

	footer .socialMedia img {
		height: 25px;
	}
}

@media (min-width: 1400px) {
	footer {
		padding: 90px 130px 40px 130px;
	}
}
