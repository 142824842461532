.iconWrapper {
	flex-basis: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.iconWrapper img {
	height: 19px;
}
