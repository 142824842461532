.pm_checkListItem {
    padding: 10px 10px 10px 10px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    width: 280px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
}

.pm_headline {
    margin-bottom: 10px;
}

.pm_input {
    outline: none;
    border: unset;
    font-weight: 700;
    color: #525252;
    line-height: 25px;
}

.pm_input::placeholder {
    color: #525252;
    line-height: 25px;
}

.PopMerkliste .DayPicker {
    margin-top: 10px;
    margin-bottom: 30px;
}

.PopMerkliste .innerPopUp {
    max-height: 90%;
    overflow-y: auto;
    justify-content: unset;
    position: relative;
}

.cancelIcon {
    position: absolute;
    right: 15px;
    top: 5px;
    font-size: 25px;
    transform: rotate(45deg);
    cursor: pointer;
}