button.imgButton {
	border: none;
	border-radius: 5px;
	color: #fff;
	font-size: 25px;
	font-weight: 700;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	width: 100%;
	max-width: 400px;
	height: 235px;
	background-position: center;
	background-size: cover;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	position: relative;
}

.we_overlay {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #00000063;
	z-index: 10;
}

button.imgButton img,
button.imgButton span {
	position: absolute;
	width: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

button.imgButton img {
	z-index: 1;
	height: 100%;
	object-fit: cover;
}

button.imgButton span {
	z-index: 10;
	text-shadow: 0px 0px 5px rgb(0 0 0 / 30%);
	text-transform: uppercase;
	padding: 0 15px 0 15px;
    word-break: break-word;
    box-sizing: border-box;
}
