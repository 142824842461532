.sa_iconContainer {
    display: flex;
    align-items: center;
    padding: 1px 0 1px 0;
}

.sa_text {
    margin-left: 5px;
    font-size: 14px;
}

.sa_headline {
    margin-bottom: 5px;
    font-weight: 800;
}

.sa_info {
    text-align: left;
    width: 100%;
    margin-left: 15px;
}

.SmallAd {
    background-color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.sa_image img {
    display: block;
}

.sa_image img {
    width: 230px;
    height: 190px;
    object-fit: cover;
    border-radius: 5px 0 0 5px;
}

.sa_moreInfo {
    border: 1px solid lightgray;
    padding: 7px 0 7px 0;
    width: 95%;
    text-align: center;
    font-size: 14px;
    border-radius: 5px;
    margin-top: 5px;
}