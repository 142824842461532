.CreateAd {
  width: 1200px;
  max-width: 90%;
  background-color: #f6f6f5;
  margin: auto;
  padding-bottom: 60px;
}

.CreateAd .checkboxContainer {
  display: flex;
  align-items: center;
}

.CreateAd .headline h2 {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 800;
  color: black;
  text-align: center;
}

.CreateAd .headline {
  margin-top: 50px;
  margin-bottom: 30px;
}

.CreateAd .checkboxSwitch {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 30px 20px 30px;
  margin: 0 10px 0 10px;
  background-color: white;
  box-shadow: 1px 1px 5px #0000001a;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.CreateAd .selectContainer {
  margin: 20px 0 0px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.CreateAd .selectContainer select {
  flex-basis: 48%;
  padding: 10px;
  text-align: center;
  border: unset;
  outline: none;
  padding: 20px;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px #0000001a;
  line-height: 20px;
}

.CreateAd .selectItem {
  margin-top: 20px;
  padding: 10px;
  text-align: center;
  border: unset;
  outline: none;
  padding: 20px;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px #0000001a;
}

.CreateAd .selectItemQuickInfo {
  border: unset;
  outline: none;
  font-size: 16px;
  background-color: unset;
}

.CreateAd .textField {
  margin-top: 20px;
  width: 100%;
  padding: 20px;
  outline: none;
  border: unset;
  box-shadow: 1px 1px 5px #0000001a;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
}

.CreateAd .textFieldHeadline {
  font-size: 25px;
  font-weight: 800;
  line-height: 40px;
  padding: 15px;
  text-transform: uppercase;
}

.CreateAd .textFieldHeadline::placeholder {
  font-weight: 800;
  line-height: 40px;
  padding: 15px;
}

.CreateAd .textFieldHeadlineMiddle {
  font-size: 18px;
  font-weight: 800;
  line-height: 30px;
  padding: 15px;
  text-transform: uppercase;
}

.CreateAd .textFieldHeadlineMiddle::placeholder {
  font-weight: 800;
  line-height: 28px;
}

.CreateAd .fullServiceContainer {
  background-color: white;
  margin: 15px 0 15px 0;
  padding: 25px;
  border-radius: 5px;
}

.CreateAd .activePlan {
  color: white;
  font-weight: 700;
  background-size: 200% 200%;
  background-image: url("../../img/Verlauf.png");
  background-attachment: fixed;
  animation: diagonal alternate 30s infinite;
}

.CreateAd textarea {
  margin-top: 20px;
  width: 100%;
  padding: 20px;
  outline: none;
  border: unset;
  box-shadow: 1px 1px 5px #0000001a;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: left;
  font-size: 14px;
  resize: none;
  line-height: 25px;
}

.CreateAd .galleryAndContent {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}

.CreateAd .galleryAndContent .secondColumn {
  flex-basis: 50%;
}

.CreateAd .galleryAndContent .firstColumn {
  flex-basis: 50%;
}

.CreateAd .infoPoint {
  display: flex;
  align-items: center;
  padding: 15px 0 0 0;
}

.CreateAd .checkboxServiceContainer {
  display: flex;
  flex-wrap: wrap;
}

.CreateAd .checkboxContainer {
  flex-basis: 50%;
}

.CreateAd .submitAdButton {
  padding: 20px 40px 20px 40px;
  background-color: #f9b300;
  color: white;
  font-weight: 800;
  font-size: 18px;
  outline: none;
  border: unset;
  border-radius: 5px;
  cursor: pointer;
}

.ga_thumbnail .react-thumbnail-generator img {
  width: 50px;
  height: 50px;
  display: block;
  object-fit: cover;
}

.ga_thumbnail .react-thumbnail-generator {
  width: 50px;
  height: 50px;
}

.alarmContainer {
  padding-top: 40px;
  background-color: white;
  text-align: center;
}

.alarmContainer h2 {
  font-weight: 800;
}

.alarmText {
  max-width: 800px;
  margin: auto;
  padding: 30px 0 30px 0;
  line-height: 25px;
}

.DayPicker {
  margin-top: 40px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.textFieldQuick::placeholder {
  color: rgb(82, 82, 82);
}

.productChoose {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.ap_item_choose:hover {
  box-shadow: 0 0 0 4px rgb(249, 179, 0);
  border-radius: 5px;
}

.ap_item_choose{
  cursor: pointer;
}
