nav.standardNav {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 70px auto;
	grid-template-areas:
		"logo navToggle"
		"nav nav";
	align-items: center;
	color: #fff;
	font-weight: 600;
}

nav.standardNav img {
	grid-area: logo;
	width: 150px;
	cursor: pointer;
}

nav.standardNav label#lblNavToggle {
	grid-area: navToggle;
	padding: 20px 3px;
	justify-self: end;
}

nav.standardNav #navToggle {
	display: none;
}

nav.standardNav .navIcon,
nav.standardNav .navIcon::before,
nav.standardNav .navIcon::after {
	width: 40px;
	height: 2px;
	background-color: #fff;
	transition: 0.3s ease;
}

nav.standardNav .navIcon {
	position: relative;
}

nav.standardNav .navIcon::before,
nav.standardNav .navIcon::after {
	content: "";
	display: block;
	position: absolute;
}

nav.standardNav .navIcon::before {
	top: -10px;
}

nav.standardNav .navIcon::after {
	bottom: -10px;
}

nav.standardNav ul {
	grid-area: nav;
	list-style: none;
	padding: 0;
	margin-top: 10px;
	background: #fff;
	border-radius: 5px;
	color: #000;
	font-size: 14px;
	line-height: 1.5em;
	max-height: 0px;
	overflow: hidden;
	transition: 0.5s ease;
}

nav.standardNav ul li {
	padding: 10px 20px;
	cursor: pointer;
}

nav.standardNav ul li:not(:last-child) {
	border-bottom: solid 1px lightgrey;
}

nav.standardNav #navToggle:checked ~ ul.links {
	max-height: 170px;
}

nav.standardNav #navToggle:checked ~ label#lblNavToggle .navIcon {
	background-color: transparent;
}

nav.standardNav #navToggle:checked ~ label#lblNavToggle .navIcon::before {
	transform: rotate(45deg);
	top: 0;
}

nav.standardNav #navToggle:checked ~ label#lblNavToggle .navIcon::after {
	transform: rotate(-45deg);
	bottom: 0;
}

@media (min-width: 900px) {
	nav.standardNav {
		grid-template-columns: auto 1fr;
		grid-template-rows: auto;
		grid-template-areas: "logo nav";
		grid-gap: 30px;
	}

	nav.standardNav #lblNavToggle {
		display: none;
	}

	nav.standardNav ul.links {
		background-color: transparent;
		max-height: unset;
		justify-self: end;
		display: flex;
		gap: 30px;
	}

	nav.standardNav ul.links li {
		background-color: transparent;
		padding: 0;
		border: none;
	}

	nav.standardNav ul.links li a {
		color: #fff;
	}
}
