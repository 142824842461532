.kategorien h2 {
	text-align: center;
	font-size: 25px;
	font-weight: 800;
}

.kategorien header {
	padding-bottom: 0;
}

.kategorien header h2 {
	color: #fff;
	margin-top: 50px;
}

.kategorien header h3 {
	text-align: center;
	color: #fff;
	font-size: 14px;
}

.kategorien main section {
	padding: 10px 20px 50px 20px;
	background-color: #fff;
}

.kategorien p {
	text-align: center;
	margin-bottom: 50px;
}

.kategorien section .imgButtons {
	width: 90%;
	max-width: 1200px;
	margin: auto;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 50px;
}

@media (min-width: 500px) {
	.kategorien main section {
		padding: 0px 50px 70px 50px;
	}
}

@media (min-width: 981px) {
	.kategorien h2 {
		font-size: 35px;
	}
}
