* {
	margin: 0;
}

main.landing {
	font-family: "Spartan", Helvetica, Arial, Lucida, sans-serif;
}

main.landing h2 {
	font-weight: 800;
	text-transform: uppercase;
	font-size: 32px;
	line-height: 1.5em;
	text-align: center;
	margin-bottom: 55px;
}

main.landing section {
	padding: 100px 30px;
}

main.landing p {
	font-size: 16px;
	font-weight: 500;
	color: #525252 !important;
	line-height: 28px;
}

main.landing .information .bulletpoints {
	list-style-type: none;
	padding: 0;
	display: flex;
	flex-direction: column;
}

main.landing .information .bulletpoints > div:first-child {
	margin-bottom: 90px;
}

main.landing .information .bulletpoints .point:not(:last-child) {
	margin-bottom: 90px;
}

main.landing .information .bulletpoints .point {
	flex-basis: 100%;
	flex-shrink: 0;
}

main.landing .accountTypes {
	background-color: #f6f5f5 !important;
}

main.landing .accountTypes > p {
	text-align: center;
	margin-bottom: 50px;
	color: #666 !important;
}

main.landing .accountTypes > div {
	display: flex;
	flex-direction: column;
}

main.landing .accountTypes > div > div:first-child {
	margin-bottom: 30px;
}

main.landing .accountTypes > div > div span {
	display: block;
	font-size: 18px;
	font-weight: 800;
	line-height: 18px;
	color: #525252 !important;
	text-align: center;
	margin-bottom: 20px;
}

main.landing .accountTypes > div > div img {
	width: 100%;
	box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 30%);
}

main.landing section.prices {
	padding: 54px 30px;
	background-size: 200% 200%;
	background-image: url("../../img/Verlauf.png");
	background-attachment: fixed;
	animation: diagonal alternate 30s infinite;
}

main.landing section.prices h2 {
	color: #fff !important;
}

main.landing .priceCards {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

main.landing .priceCards .pricecard {
	flex-basis: 100%;
}

main.landing .pricecard:last-child .price div:last-child {
	margin-top: 30px;
	margin-left: 0;
}

@media (min-width: 470px) {
	main.landing .pricecard:last-child .price {
		flex-direction: row !important;
	}

	main.landing .pricecard:last-child .price div:last-child {
		margin-top: 0;
		margin-left: 30px;
	}
}

@media (min-width: 768px) {
	main.landing .accountTypes {
		padding: 100px 10%;
	}

	main.landing .priceCards .pricecard {
		flex-basis: 31%;
	}
}

@media (min-width: 981px) {
	main.landing .information {
		margin: auto;
	}

	main.landing .information .bulletpoints {
		max-width: 1200px;
		margin: auto;
		flex-direction: row;
		justify-content: space-between;
	}

	main.landing .information .bulletpoints > div {
		flex-basis: 48%;
	}

	main.landing .accountTypes > div {
		flex-direction: row;
		justify-content: space-between;
	}

	main.landing .accountTypes > div > div:first-child {
		flex-basis: 28%;
	}

	main.landing .accountTypes > div > div:last-child {
		flex-basis: 68%;
	}

	main.landing .priceCards {
		flex-direction: row;
		max-width: 1200px;
		margin: auto;
		align-items: flex-start;
	}

	main.landing .pricecard:last-child .price {
		flex-direction: column !important;
	}

	main.landing .pricecard:last-child .price div:last-child {
		margin-top: 30px;
		margin-left: 0px;
	}
}

@media (min-width: 1260px) {
	main.landing .pricecard:last-child .price {
		flex-direction: row !important;
	}

	main.landing .pricecard:last-child .price div:last-child {
		margin-top: 0;
		margin-left: 30px;
	}
}
