form.searchForm{
    display: grid;
	grid-template-columns: 1fr;
	gap: 15px;
}

#searchForm--desktop-only{
	display: none;
}

form.searchForm .CustomSelect {
	margin-top: 0 !important;
	height: inherit;
}

form.searchForm .labelEventSuche {
	position: relative;
	cursor: pointer;
}

form.searchForm .labelEventSuche span {
	position: absolute;
	top: 50%;
	left: 15px;
	transform: translateY(-50%);
	font-size: 14px;
}

form.searchForm .inpEventSuche {
	box-sizing: border-box;
	height: 100%;
	width: 100%;
	border: none;
	border-radius: 5px;
	padding: 20px !important;
	font-size: 14px;
}

form.searchForm .inpEventSuche:focus-visible {
	outline: none;
}

form.searchForm .inpEventSuche:focus + span {
	display: none !important;
}

form.searchForm .inpEventSuche.hasContent + span {
	display: none !important;
}

form.searchForm button {
	background-color: #fff;
	border: none;
	border-radius: 5px;
    cursor: pointer;
}

form.searchForm.normal-design {
	max-width: 1200px;
	margin: auto;
}

form.searchForm.normal-design > * {
	height: 65px;
}

form.searchForm.normal-design .labelEventSuche span {
	left: 15px;
}

form.searchForm.normal-design button.btnSuchen {
	background-color: #f9b306;
	font-size: 30px;
	font-weight: 800;
	color: #fff;
	line-height: 100%;
	padding: 20px;
}

form.searchForm.inside-scroll-nav-design {
	margin: 15px auto 0 auto;
}

form.searchForm.inside-scroll-nav-design > * {
	height: 55px;
}

form.searchForm.inside-scroll-nav-design .labelEventSuche span {
	left: 20px;
	right:20px;
	font-weight: 600;
}

form.searchForm.inside-scroll-nav-design .inpEventSuche {
	min-width: 153px;
	background-color: transparent;
	border: solid 2px #fff;
	color: #fff;
	cursor: pointer;
}

form.searchForm.inside-scroll-nav-design button {
	background-color: transparent;
	color: #fff;
	border: solid 2px #fff;
	font-weight: 600;
	padding: 20px;
}

form.searchForm.inside-scroll-nav-design button.btnNotfall {
	padding: 0 20px;
}

form.searchForm.inside-scroll-nav-design#searchForm--desktop-only{
    grid-area: horizontalForm;
}

@media (min-width: 900px) {
	form.searchForm.normal-design{
		grid-template-columns: 1fr 1fr;
	}

    form.searchForm.normal-design.notdienstActive > .wrapperBtnNotfall {
		grid-area: 2 / 1 / 2 / 3;
	}

	form.searchForm.normal-design.notdienstActive > button.btnSuchen {
		grid-area: 3 / 1 / 3 / 3;
	}

	form.searchForm.normal-design > button.btnSuchen {
		grid-area: 2 / 1 / 2 / 3;
	}

	form.searchForm.inside-scroll-nav-design.notdienstActive#searchForm--desktop-only {
		margin: 0;
		grid-template-columns: 1fr 1fr auto 1fr;
	}
}

@media (min-width:1016px) {
	#searchForm--mobile-only{
		display: none;
	}
	#searchForm--desktop-only{
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
	}

	.normal-design.notdienstActive#searchForm--desktop-only{
		display: grid;
		grid-template-columns: 1fr 1fr 120px 1fr;
	}
}

@media (min-width: 1220px) {
	form.searchForm.normal-design {
		grid-template-columns: 1fr 1fr 1fr;
	}

	form.searchForm.normal-design.notdienstActive {
		grid-template-columns: 1fr 1fr 120px 1fr;
	}


	form.searchForm.normal-design.notdienstActive > button.btnSuchen, form.searchForm.normal-design.notdienstActive > .wrapperBtnNotfall {
		grid-area: unset;
	}
}

@media(min-width:1615px){
    form.searchForm.inside-scroll-nav-design {
		margin: 0;
		grid-template-columns: 2fr 2fr 1fr;
	}
	
	form.searchForm.inside-scroll-nav-design.notdienstActive {
		margin: 0;
		grid-template-columns: 1fr 1fr 8% 1fr;
	}
}