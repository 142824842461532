.wrapperBtnNotfall{
    position: relative;
    color:#525252;
}

.wrapperBtnNotfall button.btnNotfall {
    padding: 20px;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 0px 3px transparent;
}

.wrapperBtnNotfall .customDayPickerWrapper {
    padding:20px;
    background:white;
    position: absolute;
    top:calc(100% + 10px);
    left:50%;
    transform: translateX(-50%);
    border-radius: 5px;
    z-index: 10;
    width: min-content;
    font-weight: normal;
}

.wrapperBtnNotfall .customDayPickerWrapper::after{
    content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
}

.wrapperBtnNotfall .customDayPickerWrapper > h3{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #525252;
}

.wrapperBtnNotfall .customDayPickerWrapper button{
    width: 100%;
    background-color: #F9B306 !important;
    padding: 15px;
    font-size: 14px;
    font-weight: 700;
    color:white;
}

.wrapperBtnNotfall .customDayPickerWrapper .DayPicker{
    margin-top: 0;
    border:none
}

button.notdienstActive{
    animation:1s redBorderBlink infinite;
    color:red;
}

@keyframes redBorderBlink {
    0%{box-shadow: 0px 0px 0px 3px red;}
    50%{box-shadow: 0px 0px 0px 3px transparent;}
    100%{box-shadow: 0px 0px 0px 3px red;}
}