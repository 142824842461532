

.gratisAnfrage.popupWrapper .popup {
	border-radius: 0px;
	text-align: left;
	overflow-y: scroll;
	/* height: 100vh; */
}

.gratisAnfrage .popup h3 {
	font-size: 14px;
	hyphens: auto;
	text-transform: uppercase;
	margin-right: 20px;
}

.gratisAnfrage .popup h4 {
	font-size: 25px;
	margin-top: 10px;
	hyphens: auto;
	text-transform: uppercase;
}

.gratisAnfrage .popup form {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 20px;
}

.gratisAnfrage .popup form input,
.gratisAnfrage .popup form textarea {
	padding: 20px;
	border-radius: 5px;
	border: solid 1px lightgrey;
	outline: none;
	box-sizing: border-box;
}

.gratisAnfrage .popup form input:focus,
.gratisAnfrage .popup form textarea:focus {
	border: solid 1px #F9B308!important;
}

.gratisAnfrage .popup form textarea {
	height: 100px;
}

.gratisAnfrage .popup .lblAgb-check {
	font-size: 14px;
	text-align: left;
	display: flex;
}

.gratisAnfrage .popup input[type="checkbox"] {
	margin-right: 10px;
}

.contactHalf {
	display: flex;
	gap: 10px;
}

@media only screen and (max-width: 730px) {
	.contactHalf {
		flex-direction: column;
	}
	.contactHalf input {
		width: 100%!important;
	}
}


@media (min-width: 470px) {
	.gratisAnfrage.popupWrapper .popup {
		border-radius: 10px;
		text-align: center;
		/* height: calc(100vh - 40px); */
	}
}
