.providerStart {
  text-align: center;
  background-color: #f6f6f5;
  margin-bottom: 100px;
}

.providerStart h2 {
  font-weight: 800;
  margin-top: 50px;
  padding-bottom: 50px;
}

.providerStart main {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas:
    "info random3"
    "questions random1"
    "questions random2"
    "questions random4"
    "questions random4";
  grid-gap: 40px;
  width: 90%;
  max-width: 1200px;
  margin: auto;
}

.container {
  background: #fff;
  border-radius: 7px;
  padding: 35px 35px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  text-align: left;
}

.generalInformation {
  grid-area: info;
  text-align: left;
}

.generalInformation h3 {
  font-weight: 700;
  padding-bottom: 25px;
}

.generalInformation p {
  line-height: 1.6em;
  font-size: 14px;
}

.generalInformation p:not(:last-child) {
  padding-bottom: 25px;
}

#random1 {
  grid-area: random1;
}

#random2 {
  grid-area: random2;
}

.questions {
  grid-area: questions;
}

.questions h3 {
  margin-bottom: 40px;
}

@media (max-width: 1050px) {
  .providerStart main {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "info"
      "random1"
      "random2"
      "questions";
    grid-gap: 30px;
  }

  .questions {
    margin-top: 40px;
  }

  .questions h3 {
    margin-bottom: 30px;
  }
}

.newsSection {
  padding-top: 20px;
  padding-bottom: 20px;
  word-break: break-all;
}

.newsText {
  word-break: break-word;
}

.newsTitle {
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  padding-bottom: 10px;
  word-break: break-all;
}

.newsDate {
  font-size: 10px;
  font-weight: 600;
  padding-top: 10px;
}
