.AdsProfile {
    text-align: center;
    background-color:#F6F6F5;
    margin-bottom: 100px;
}

.AdsProfile h2 {
    font-weight: 800;
    margin-top: 40px;
    padding-bottom: 50px;
}

.ap_editBar {
    display: flex;
}

.ap_adsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    width: 90%;
    margin: auto;
}

.ap_item {
    flex-basis: 48%;
}

.ap_info {
    flex-basis: 100%;
    background-color: white;
    margin: 5px 5px 5px 0;
    text-align: right;
    border-radius: 5px 0 0 5px;
    display: flex;
    align-items: center;
    font-size: 14px;
}


.ap_info img {
    margin-right: 10px;
    margin-left: auto;
}

.ap_delete {
    flex-basis: 15%;
    background-color: white;
    margin: 5px 5px 5px 0;
}

.ap_edit {
    flex-basis: 15%;
    background-color: white;
    margin: 5px 0 5px 0;
    background-color: lightblue;
    border-radius: 0 5px 5px 0;
}

.ap_editBar img {
    width: 30px;
    height: 30px;
    padding: 5px;
}

.ap_editBarCustom {
    width: 100%;
}

.ap_editBarCustom > div {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.ergebnisse h4 {
    text-align: left;
}

.btnNotdienst {
    border-radius: 0 0 0 5px;
}

.infoBanner {
    grid-area: infoBanner;
}